import hi from "@/assets/homepage/hi.png";
import harper from "@/assets/homepage/harper.png";
import lawrence from "@/assets/homepage/lawrence.png";
import piper from "@/assets/homepage/piper.png";
import xps from "@/assets/homepage/xps.png";
import scipro from "@/assets/homepage/scipro.png";
import LinkedInIcon from "@/assets/icons/footer/linkedin-logo.svg";
import FacebookIcon from "@/assets/icons/footer/facebook-logo.svg";
import InstagramIcon from "@/assets/icons/footer/instagram-logo.svg";
import XiIcon from "@/assets/icons/footer/xi-logo.svg";
import YoutubeIcon from "@/assets/icons/footer/youtube-logo.svg";
import WhiteLogo from "@/assets/logos/white-logo.png";
import PurpleLogo from "@/assets/logos/color-logo.png";
import LiLightIcon from "@/assets/icons/li-light.svg";
import FbLightIcon from "@/assets/icons/fb-light.svg";
import LgLightIcon from "@/assets/icons/lg-light.svg";
import XLightIcon from "@/assets/icons/x-light.svg";
import YoutubeLightIcon from "@/assets/icons/youtube-light.svg";

const BG_VARIANTS = {
  green: "bg-green",
  white: "bg-white",
  purple: "bg-purple",
  pink: "bg-pink",
  transparent: "bg-transparent",
  gainsboro: "bg-gainsboro-light",
  gradientBlue: "bg-gradientBlue",
  grey: "bg-grey-100",
};

const COLOR_VARIANTS = {
  green: "text-green",
  white: "text-white",
  purple: "text-purple",
};

const BORDER_VARIANTS = {
  green: "border-green",
  white: "border-white",
  purple: "border-purple",
};

const HOVER_COLOR_VARIANTS = {
  green: "hover:text-green",
  white: "hover:text-white",
  purple: "hover:text-purple",
  transparent: "hover:text-white",
  gainsboro: "hover:text-white",
  pink: "hover:text-pink",
  gradientBlue: "hover:bg-gradientBlue",
  grey: "hover:bg-pink",
};

const HOVER_BG_VARIANTS = {
  green: "hover:bg-green",
  white: "hover:bg-white",
  purple: "hover:bg-purple",
  gainsboro: "hover:bg-purple",
};

const STACK_TITLE = [
  "Privacy",
  "Legal",
  "Data & AI",
  "Cyber Security",
  "Financial Services",
  "Software Engineering",
  "Cloud Infrastructure",
  "DevOps",
  "ERP",
  "Business Change",
  "CRM",
  "HR",
];

const SpecialismPaths = [
  { name: "Business Change", urlSlug: "business-change" },
  {
    name: "Cloud Infrastructure & DevOps",
    urlSlug: "cloud-infrastructure-dev-ops",
  },
  { name: "CRM", urlSlug: "crm" },
  { name: "Cyber Security", urlSlug: "cyber-security" },
  { name: "Data & AI", urlSlug: "data-ai" },
  {
    name: "ERP Applications & Transformation",
    urlSlug: "erp-applications-transformation",
  },
  { name: "Financial Services", urlSlug: "financial-services" },
  { name: "HR Transformation", urlSlug: "hr-transformation" },
  { name: "Legal", urlSlug: "legal" },
  { name: "Privacy", urlSlug: "privacy" },
  { name: "Software Engineering", urlSlug: "software-engineering" },
  { name: "Key Accounts", urlSlug: "key-accounts" },
];

const NAVBAR_STYLES = {
  "/": {
    color: "text-white",
    img: WhiteLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-white",
    icon: "fill-white",
    searchIcon: "fill-purple",
  },
  "/jobs": {
    color: "text-white",
    img: WhiteLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-white",
    icon: "fill-white",
    searchIcon: "fill-purple",
  },
  "/jobs/thank-you-for-applying": {
    color: "text-purple",
    img: PurpleLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-purple",
    icon: "fill-purple",
    searchIcon: "fill-purple",
  },
  "/job-ad": {
    color: "text-purple",
    img: PurpleLogo,
    btnBg: "purple",
    btnColor: "white",
    menu: "bg-purple",
    icon: "fill-purple",
    searchIcon: "fill-white",
  },

  "/specialisms/[slug]": {
    color: "text-white",
    img: WhiteLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-white",
    icon: "fill-white",
    searchIcon: "fill-purple",
  },
  "/about": {
    color: "text-white",
    img: WhiteLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-white",
    icon: "fill-white",
    searchIcon: "fill-purple",
  },
  "/team": {
    color: "text-white",
    img: WhiteLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-white",
    icon: "fill-white",
    searchIcon: "fill-purple",
  },
  "/join-us": {
    color: "text-white",
    img: WhiteLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-white",
    icon: "fill-white",
    searchIcon: "fill-purple",
  },
  "/legal": {
    color: "text-white",
    img: WhiteLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-white",
    icon: "fill-white",
    searchIcon: "fill-purple",
  },
  "/contact": {
    color: "text-white",
    img: WhiteLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-white",
    icon: "fill-white",
    searchIcon: "fill-purple",
  },
  "/newsroom": {
    color: "text-white",
    img: WhiteLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-white",
    icon: "fill-white",
    searchIcon: "fill-purple",
  },
  "/clients": {
    color: "text-white",
    img: WhiteLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-white",
    icon: "fill-white",
    searchIcon: "fill-purple",
  },
  "/vacancies": {
    color: "text-purple",
    img: PurpleLogo,
    btnBg: "purple",
    btnColor: "white",
    menu: "bg-purple",
    icon: "fill-purple",
    searchIcon: "fill-white",
  },
  "/newsroom/[slug]": {
    color: "text-purple",
    img: PurpleLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-purple",
    icon: "fill-purple",
    searchIcon: "fill-purple",
  },
  "/csr": {
    color: "text-white",
    img: WhiteLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-white",
    icon: "fill-white",
    searchIcon: "fill-purple",
  },
  "/candidates": {
    color: "text-white",
    img: WhiteLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-white",
    icon: "fill-white",
    searchIcon: "fill-purple",
  },
  "/service": {
    color: "text-white",
    img: WhiteLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-white",
    icon: "fill-white",
    searchIcon: "fill-purple",
  },
  "/not-found": {
    color: "text-purple",
    img: PurpleLogo,
    btnBg: "green",
    btnColor: "purple",
    menu: "bg-purple",
    icon: "fill-purple",
    searchIcon: "fill-purple",
  },
};

const FOOTER_LOGOS = [
  {
    url: hi ?? "",
    title: "Parent company and employer brand",
    width: "max-w-[100px]",
    id: 1,
    link: "https://www.wearelhi.com/?source=www.lawrenceharvey.com",
  },
  {
    url: lawrence ?? "",
    title: "Technology recruitment",
    width: "max-w-[75px]",
    id: 2,
    link: "https://www.lawrenceharvey.com/?source=www.lawrenceharvey.com",
  },
  {
    url: scipro ?? "",
    title: "Life Science recruitment",
    width: "max-w-[76px]",
    id: 3,
    link: "https://www.sciproglobal.com/",
  },
  {
    url: harper ?? "",
    title: "Built Environment recruitment",
    width: "max-w-[123px]",
    id: 4,
    link: "https://www.harperharrison.com/?source=www.lawrenceharvey.com",
  },
  {
    url: piper ?? "",
    title: "Clean Energy recruitment",
    width: "max-w-[85px]",
    id: 5,
    link: "https://www.pipermaddox.com/?source=www.lawrenceharvey.com",
  },

  {
    url: xps ?? "",
    title: "Project Delivery solutions",
    width: "max-w-[80px]",
    id: 6,
    link: "https://www.wearexps.com/?source=www.lawrenceharvey.com",
  },
];

const FOOTER_WEBSITE = [
  {
    id: 1,
    Icon: LinkedInIcon,
    name: "LinkedIn",
    path: "https://www.linkedin.com/uas/login?session_redirect=%2Fcompany%2F144455",
  },
  {
    id: 2,
    Icon: InstagramIcon,
    name: "Instagram",
    path: "https://www.instagram.com/lawrenceharvey_/",
  },
  {
    id: 3,
    Icon: FacebookIcon,
    name: "Facebook",
    path: "https://www.facebook.com/LawrenceHarveyRecruitment",
  },
  {
    id: 4,
    Icon: XiIcon,
    name: "Twitter",
    path: "https://twitter.com/i/flow/login?redirect_after_login=%2FLawrenceHarvey_",
  },
  {
    id: 5,
    Icon: YoutubeIcon,
    name: "YouTube",
    path: "https://www.youtube.com/channel/UC6go7Begrkc84GcmMvtnbDg",
  },
];
const HEADER_WEBSITE = [
  LiLightIcon,
  LgLightIcon,
  FbLightIcon,
  XLightIcon,
  YoutubeLightIcon,
];

const MENU_ITEMS = [
  {
    id: 1,
    title: "About",
    link: "/about",
  },
  {
    id: 2,
    title: "Specialism",
    link: "#",
    children: SpecialismPaths.map((path, idx) => ({
      id: idx,
      title: path.name,
      link: `/specialisms/${path.urlSlug}`,
    })),
  },
  {
    id: 3,
    title: "Candidates",
    link: "/candidates",
  },
  {
    id: 4,
    title: "Clients",
    link: "/clients",
    children: [
      {
        id: 1,
        title: "Submit a vacancy",
        link: "/vacancies",
      },
    ],
  },
  {
    id: 5,
    title: "Team",
    link: "/team",
    children: [
      {
        id: 1,
        title: "Join us",
        link: "/join-us",
      },
    ],
  },
  {
    id: 6,
    title: "Newsroom",
    link: "/newsroom",
  },
  // {
  //   id: 7,
  //   title: "CSR",
  //   link: "/csr",
  //   children: [],
  // },
  {
    id: 8,
    title: "Contact",
    link: "/contact",
    children: [],
  },
];

const EN_US_MENU_ITEMS = [
  {
    id: 1,
    title: "About",
    link: "/usa/about",
  },
  {
    id: 2,
    title: "Specialism",
    link: "#",
    children: SpecialismPaths.map((path, idx) => ({
      id: idx,
      title: path.name,
      link: `/usa/specialisms/${path.urlSlug}`,
    })),
  },
  {
    id: 3,
    title: "Candidates",
    link: "/usa/candidates",
  },
  {
    id: 4,
    title: "Clients",
    link: "/usa/clients",
  },
  {
    id: 5,
    title: "Team",
    link: "/usa/team",
    children: [
      {
        id: 1,
        title: "Join us",
        link: "/usa/join-us",
      },
    ],
  },
  {
    id: 6,
    title: "Newsroom",
    link: "/usa/newsroom",
  },
  // {
  //   id: 7,
  //   title: "CSR",
  //   link: "/usa/csr",
  //   children: [],
  // },
  {
    id: 8,
    title: "Contact",
    link: "/usa/contact",
    children: [],
  },
];

const DE_MENU_ITEMS = [
  {
    id: 1,
    title: "Über uns",
    link: "/de/about",
  },
  {
    id: 2,
    title: "Spezialisierung",
    link: "#",
    children: SpecialismPaths.map((path, idx) => ({
      id: idx,
      title: path.name,
      link: `/de/specialisms/${path.urlSlug}`,
    })),
  },
  {
    id: 3,
    title: "Kandidaten",
    link: "/de/candidates",
  },
  {
    id: 4,
    title: "Kunden",
    link: "/de/clients",
  },
  {
    id: 5,
    title: "Team",
    link: "/de/team",
    children: [
      {
        id: 1,
        title: "Begleiten Sie uns",
        link: "/de/join-us",
      },
    ],
  },
  {
    id: 6,
    title: "Nachrichtenraum",
    link: "/de/newsroom",
  },
  // {
  //   id: 7,
  //   title: "CSR",
  //   link: "/de/csr",
  //   children: [],
  // },
  {
    id: 8,
    title: "Kontakt",
    link: "/de/contact",
    children: [],
  },
];

export {
  BG_VARIANTS,
  COLOR_VARIANTS,
  BORDER_VARIANTS,
  HOVER_COLOR_VARIANTS,
  HOVER_BG_VARIANTS,
  FOOTER_LOGOS,
  FOOTER_WEBSITE,
  NAVBAR_STYLES,
  MENU_ITEMS,
  EN_US_MENU_ITEMS,
  HEADER_WEBSITE,
  STACK_TITLE,
  DE_MENU_ITEMS,
};
